/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/webapp/apps/common/js/jquery/ui/1.10/i18n/jquery.ui.datepicker-en-GB-aa.js
*    TO   --> ./webapps/shared-web/src/main/assets/js/plugins/date-picker/i18n/jquery.ui.datepicker-en-GB-aa.js
**/

/* Initialization in GB English (customized for AA.com) for the datepicker from jQuery UI */
/* Ricardo Viana */
jQuery(function($){
	$.datepicker.regional['en_GB'] = {
		closeText: 'Close',
		prevText: 'Prev',
		nextText: 'Next',
		currentText: 'Today',
		monthNames: ['January','February','March','April','May','June',
		'July','August','September','October','November','December'],
		monthNamesShort: ['Jan','Feb','Mar','Apr','May','Jun',
		'Jul','Aug','Sep','Oct','Nov','Dec'],
		dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
		dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
		dayNamesMin: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
		weekHeader: 'Wk',
		dateFormat: 'dd/mm/yy',
		firstDay: 1,
		isRTL: false,
		showMonthAfterYear: false,
		yearSuffix: ''};
	$.datepicker.setDefaults($.datepicker.regional['en_GB']);
});
